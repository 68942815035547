import swal from "sweetalert2";
import reviewDelegationModalController from "../MyReviews/DelegationModal/reviewDelegationModal.controller";
import ticketModalController from "../MyReviews/TicketModal/ticketModal.controller";
import attachmentsModalController from "./AttachmentsModal/attachmentsModal.controller";
import submitReviewModalController from "../MyReviews/SubmitReviewModal/submitReviewModal.controller";

export default /*@ngInject*/ function (accessCertificationsService, $scope, configurationDataService, helperService, certificationsAllReviewDataService, myReviewsDataService, $uibModal, $filter, $rootScope, archiveToastr) {

    const vm = this;

    vm.selectedReviewChanged = selectedReviewChanged;
    vm.selectedReviewerChanged = selectedReviewerChanged;
    vm.exportReview = exportReview;
    vm.refreshReviewSheduleExecution = refreshReviewSheduleExecution;
    vm.refreshReview = refreshReview;
    vm.deleteReview = deleteReview;
    vm.generateFailedImRequests = generateFailedImRequests;
    vm.downloadRejectedItemValidation = downloadRejectedItemValidation;
    vm.setSortAndFilterOptionsForRoleContentType = setSortAndFilterOptionsForRoleContentType;
    vm.helperService = helperService;

    $scope.$on('RefreshReviewScheduleExecution', () => processRefresh());
    $scope.$on('RefreshSingleReview', () => {
        getReviews();
        getReviewersList(vm.selectedReview.Id);
        if (vm.selectedReviewer) {
            vm.loadingAssignmentItems = true;
            getReviewDetails(vm.selectedReviewer);
        }
    });

    activate();

    vm.itemsRadioFilter = 'All';

    vm.reviewMenuOptions = [
        {
            text: review => {
                switch (review.object.ReviewType) {
                    case 'SecurityRole':
                        return 'Download Rejected Role Item Validation';
                        break;
                    case 'Product':
                        return 'Download Rejected Product Item Validation';
                        break;
                    case 'BusinessProcess':
                        return 'Download Rejected Business Process Item Validation';
                        break;
                    case 'Risk':
                        return 'Download Rejected Risk Item Validation';
                        break;
                    default:
                        break;
                }
            },
            click: item => {
                downloadRejectedItemValidation(item.object);
            },
            displayed: review => review.object.ReviewType !== 'SecurityRoleContent'
        },
        {
            text: 'Export',
            click: item => {
                vm.exportReview(item.object);
            }
        },
        {
            text: 'Refresh',
            click: item => {
                vm.refreshReviewSheduleExecution(item.object);
            },
            displayed: modelValue => {
                const { Status } = modelValue.object;
                return Status === 'NotStarted' || Status === 'InProgress';
            }
        },
        {
            text: 'Send "Review is Ready" Notifications',
            click: item => {
                sendEmailNotifications(item.object, 4);
            },
            displayed: review => {
                const { Status } = review.object;
                return Status === 'NotStarted';
            }
        },
        {
            text: 'Send Reminder Notifications',
            click: item => {
                sendEmailNotifications(item.object, 1);
            },
            displayed: review => {
                const { Status } = review.object;
                return Status === 'NotStarted' || Status === 'InProgress';
            }
        },
        {
            text: 'Send Overdue Notifications',
            click: item => {
                sendEmailNotifications(item.object, 3);
            },
            displayed: review => {
                const { Status } = review.object;
                return Status === 'Overdue';
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: item => {
                vm.deleteReview(item.object);
            }
        }
    ];

    vm.reviewerMenuOptions = [
        {
            text: 'Delegate',
            click: async item => {
                openDelegateModal(item.object);
            },
            displayed: item => {
                return vm.checkForDelegateAllReviews && (item.object.TotalItems - item.object.CompletedItems !== 0);
            }
        },
        {
            text: 'Refresh',
            click: item => {
                item.object.Id = item.object.HeaderId;
                vm.refreshReview(item.object);
            },
            displayed: modelValue => {
                return $rootScope.UserSecurityList['9401'];
            }
        },
        {
            text: 'Submit',
            click: async item => {
                openSubmitReviewModal(item.object);
            },
            displayed: item => {
                return vm.checkForSubmitOnBehalfOf
                    && (item.object.TotalItems - (item.object.CompletedItems + item.object.ItemsCompletedByOthers)) === 0
                    && (item.object.Status === 'InProgress' || item.object.Status === 'Overdue');
            }
        },
        {
            text: 'View Tickets',
            click: item => {
                openTicketModal(item.object);
            },
            displayed: item => {
                return item.object.Tickets.length > 0;
            }
        },
        {
            text: 'View Attachments',
            click: item => {
                openAttachmentsModal(item.object);
            },
            displayed: item => {
                return item.object.Attachments.length > 0;
            }
        }
    ];

    async function activate() {
        await getReviews();
        await checkSecurityObjectInformationToggle();
        await checkForDelegateAllReviews();
        await checkForSubmitOnBehalfOf();
        vm.userDisplay = await getUserDisplay();

        vm.reviewAssignmentTiles = accessCertificationsService.getReviewTypes();
    }

    async function processRefresh() {
        if (vm.selectedReview) {
            await getReviewersList(vm.selectedReview.Id);

            if (vm.selectedReviewer) {
                getReviewDetails(vm.selectedReviewer);
            }
        }
    }

    async function getUserDisplay() {
        try {
            return await helperService.getConfigurationValue('Cert_UserDisplayField');
        } catch (err) {
            errorsDataService.catch(err);
        }

    }

    async function getReviews() {
        let response = await certificationsAllReviewDataService.getReviews();
        vm.reviewsDataSource = response.data;
    }

    async function checkSecurityObjectInformationToggle() {
        let response = await configurationDataService.getConfigurationValues();
        var config = {};

        response.data.forEach(row => {
            config[row.Name] = row.Value;
        });

        vm.showBPObject = config['Cert_BusinessProcessObjects'];
        vm.showRoleContentBPObject = config['Cert_RoleContentBusinessProcessObjects'];
    }

    async function downloadRejectedItemValidation(item) {
        await certificationsAllReviewDataService.downloadRejectedItemValidation(item.Id);
    }

    async function sendEmailNotifications(item, notificationType) {
        let postBody = {
            ReviewScheduleId: item.Id,
            ReviewScheduleNotificationType: notificationType
        };
        try {
            await certificationsAllReviewDataService.sendEmailNotifications(item.Id, postBody);
            let archiveObject = {
                correlationId: 0,
                type: null,
                message: 'Your notification has been sent.',
                title: 'Complete',
                status: 'Success',
                response: null,
                options: {},
            };
            await archiveToastr.notifyArchive(archiveObject);
        } catch {
            return;
        }

    }

    async function exportReview(item) {
        var postData = {
            "PreferredTimeZone": $rootScope.applicationTimeZone
        };
        await certificationsAllReviewDataService.exportReview(item.Id, postData);
    }

    async function checkForDelegateAllReviews() {
        vm.checkForDelegateAllReviews = await certificationsAllReviewDataService.checkForDelegateAllReviews('Delegate All Reviews');
    }

    async function checkForSubmitOnBehalfOf() {
        vm.checkForSubmitOnBehalfOf = await certificationsAllReviewDataService.checkForSubmitOnBehalfOf('Submit Review On Behalf Of');
    }

    async function openDelegateModal(delegationContext) {
        let delegationItems = [];
        let response = await myReviewsDataService.getReviewDetails(delegationContext.ReviewType, delegationContext.HeaderId, "ReviewType");
        response = response.reduce((acc, curr) => {
            if (curr.Items) {
                return acc.concat(curr.Items);
            } else {
                return acc.concat(curr.items);
            }
        }, []);
        delegationItems = response.filter(detail => detail.StatusId === null);

        let delegateModal = $uibModal.open({
            templateUrl: '/App/Components/Certifications/MyReviews/DelegationModal/reviewDelegationModal.html',
            controller: reviewDelegationModalController,
            controllerAs: 'rdmvm',
            backdrop: 'static',
            resolve: {
                delegationItems: () => delegationItems,
                reviewHeaderId: () => delegationContext.HeaderId,
                reviewType: () => delegationContext.ReviewType
            }
        });

        try {
            await delegateModal.result;
            activate();
        } catch { return; }
    }

    async function openSubmitReviewModal(review, vm) {
        let reviewModal = $uibModal.open({
            templateUrl: 'App/Components/Certifications/MyReviews/SubmitReviewModal/submitReviewModal.html',
            controller: submitReviewModalController,
            controllerAs: 'srmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                review: () => review,
                onBehalfOf: () => true
            }
        });

        try {
            await reviewModal.result;
            if (vm.selectedReview.HeaderId === review.HeaderId) {
                vm.selectedReview = null;
                vm.selectedGroup = null;
                vm.selectedUser = null;
            }
            activate();
        } catch {
            return;
        }
    }

    async function refreshReviewSheduleExecution(item) {
        try {
            await swal(
                helperService.areYouSureParams(
                    'Refresh Review',
                    'Are you sure you want to refresh the review items for this review? This has the potential of adding and removing review items.',
                    'Refresh'
                )
            );
            await certificationsAllReviewDataService.refreshReviewSheduleExecution(item.Id);
        } catch (_) {
            return;
        }
    }

    async function refreshReview(item) {
        try {
            await swal(
                helperService.areYouSureParams(
                    'Refresh Review',
                    'Are you sure you want to refresh the review items for this review? This has the potential of adding and removing review items.',
                    'Refresh'
                )
            );
            await certificationsAllReviewDataService.refreshReview(item.Id);
        } catch (_) {
            return;
        }
    }

    async function deleteReview(item) {
        try {
            await swal(helperService.areYouSureParams('Delete Review', 'Are you sure you want to delete this Review and all releated review items?', 'Delete'));
            await certificationsAllReviewDataService.deleteReview(item.Id);
            getReviews();
            if (vm.selectedReview === item) {
                vm.selectedReview = null;
            }
        } catch {
            return;
        }
    }

    async function selectedReviewChanged(selectedReview) {
        if (selectedReview && selectedReview !== vm.selectedReview) {
            vm.selectedReview = selectedReview;

            switch (vm.selectedReview.ReviewType) {
                case "Risk":
                    vm.reviewDetailsSort = ["RiskName", "UserName"];
                    vm.reviewDetailsThirdColumnFilter = ['RiskName', 'UserName', 'Company', 'CompanyIds', 'StatusName', 'Notes'];
                    break;
                case "BusinessProcess":
                    vm.reviewDetailsSort = ["BusinessProcessName", "UserName"];
                    vm.reviewDetailsThirdColumnFilter = ['BusinessProcessName', 'UserName', 'CompanyName', 'CompanyId', 'StatusName', 'Notes'];
                    break;
                case "Product":
                    vm.reviewDetailsSort = ["ProductName", "UserName"];
                    vm.reviewDetailsThirdColumnFilter = ['ProductName', 'UserName', 'CompanyName', 'CompanyId', 'StatusName', 'Notes'];
                    break;
                case "SecurityRole":
                    vm.reviewDetailsSort = ["SecurityRoleName", "UserName"];
                    vm.reviewDetailsThirdColumnFilter = ['SecurityRoleName', 'UserName', 'CompanyName', 'CompanyId', 'StatusName', 'Notes'];
                    break;
                case "SecurityRoleContent":
                    // we don't know our security role content type so we can't set sorting or filtering options yet
                    vm.reviewDetailsSort = [];
                    vm.reviewDetailsThirdColumnFilter = [];
                    break;
                default:
                    break;
            }
            vm.reviewId = selectedReview.Id;
            await getReviewersList(selectedReview.Id);
            vm.reviewItemsDataSource = null;
            if (vm.reviewersDataSource.length === 1) {
                vm.selectedReviewerChanged(vm.reviewersDataSource[0]);
            } else {
                vm.selectedReviewer = null;
            }
        }
    }

    async function getReviewersList(reviewId) {
        let response = await certificationsAllReviewDataService.getReviewersByReviewId(reviewId);
        vm.reviewersDataSource = response.data;
    }

    async function selectedReviewerChanged(selectedItem) {
        if (selectedItem && selectedItem !== vm.selectedReviewer) {
            vm.allItems = null;
            vm.currentItemsPage = 1;
            vm.itemsRadioFilter = 'All';
            vm.selectedReviewer = selectedItem;
            vm.loadingAssignmentItems = true;
            getReviewDetails(selectedItem);
        }
    }

    async function getReviewDetails(reviewer) {
        let response = await myReviewsDataService.getReviewDetails(reviewer.ReviewType, reviewer.HeaderId, 'ReviewType');
        if (response.length) {
            response = response.reduce((acc, curr) => {
                return reviewer.ReviewType === 'SecurityRoleContent' ? acc.concat(curr.items) : acc.concat(curr.Items);
            }, []);
            vm.securityRoleContentTypeFilter = response[0].RoleContentType;
            vm.securityRoleContentTypeFilterOptions = response.map(item => item.RoleContentType).unique();
            vm.securityRoleContentTypeFilterOptions = $filter('orderBy')(vm.securityRoleContentTypeFilterOptions);
            setSortAndFilterOptionsForRoleContentType(vm.securityRoleContentTypeFilter);
        }
        vm.reviewItemsDataSource = response;
        vm.reviewItemsDataSource.forEach(u => u.userDisplay = u[vm.userDisplay]);
        vm.loadingAssignmentItems = false;
    }

    async function generateFailedImRequests(reviewId) {
        await certificationsAllReviewDataService.reprocessImRequests(reviewId);
    }

    function setSortAndFilterOptionsForRoleContentType(securityRoleContentType) {
        switch (securityRoleContentType) {
            case 'BusinessProcess':
                vm.reviewDetailsSort = ['BusinessProcessName'];
                vm.reviewDetailsThirdColumnFilter = ['BusinessProcessName', 'SecurityRoleName', 'RulesetName', 'SecurityObjectAltName', 'SecurityObjectName', 'SecurityObjectTypeName', 'Notes'];
                break;
            case 'Risk':
                vm.reviewDetailsSort = ['RiskName'];
                vm.reviewDetailsThirdColumnFilter = ['RiskName', 'RulesetName', 'SecurityRoleName', 'Notes'];
                break;
            case 'RoleAccess':
                vm.reviewDetailsSort = ['SecurityObjectAltName'];
                vm.reviewDetailsThirdColumnFilter = ['SecurityObjectAltName', 'SecurityRoleName', 'SecurityObjectName', 'AccessTypeName', 'AccessLevelName', 'Notes'];
                break;
            default:
                return;

        }
    }

    function openTicketModal(review) {
        $uibModal.open({
            templateUrl: '/App/Components/Certifications/MyReviews/TicketModal/ticketModal.tmpl.html',
            controller: ticketModalController,
            controllerAs: 'tmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                review: () => review
            }
        });
    }

    async function openAttachmentsModal(review) {
        const attachmentsModal = $uibModal.open({
            templateUrl: '/App/Components/Certifications/AllReviews/AttachmentsModal/attachmentsModal.tmpl.html',
            controller: attachmentsModalController,
            controllerAs: 'amvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                review: () => review
            }
        });

        await attachmentsModal.result;
        getReviewersList(vm.reviewId);

    }

}