import swal from "sweetalert2";
import businessProcessModalController from "./businessProcessModal/businessProcessModal.controller";
import bpGroupModalController from "./bpGroupModal/bpGroupModal.controller";
import bpObjectsModalController from "./bpObjectsModal/bpObjectsModal.controller";
import rulesetObjectCopyModalController from "../../Shared/Components/rulesetObjectCopyModal/rulesetObjectCopyModal.controller";

export default /*@ngInject*/ function ($filter, $uibModal, helperService, businessProcessDataService, riskRulesetService) {

    const vm = this;

    vm.openBusinessProcessModal = openBusinessProcessModal;
    vm.openBusinessProcessGroupModal = openBusinessProcessGroupModal;
    vm.openBpObjectsModal = openBpObjectsModal;
    vm.openRulesetObjectCopyModal = openRulesetObjectCopyModal;
    vm.changeRiskRuleset = changeRiskRuleset;
    vm.changeGroupSelected = changeGroupSelected;
    vm.changeBusinessProcessSelected = changeBusinessProcessSelected;
    vm.removeObjectFromBpGroup = removeObjectFromBpGroup;
    vm.deleteBusinessProcess = deleteBusinessProcess;
    vm.deleteGroup = deleteGroup;
    vm.selectBusinessProcess = selectBusinessProcess;
    vm.selectAllBPs = selectAllBPs;

    vm.helperService = helperService;

    vm.newFieldValueIsCollapsed = true;
    vm.businessProcessMenuOptions = [
        {
            text: 'Edit',
            click: function ($itemScope, $event, modelValue) {
                var item = modelValue;
                vm.openBusinessProcessModal(item);
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: function ($itemScope, $event, modelValue) {
                var item = modelValue;
                vm.deleteBusinessProcess(item);
            }
        }
    ];
    vm.groupMenuOptions = [
        {
            text: 'Edit',
            click: function ($itemScope, $event, modelValue) {
                var item = modelValue;
                vm.openBusinessProcessGroupModal(item);
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: function ($itemScope, $event, modelValue) {
                var item = modelValue;
                vm.deleteGroup(item);
            }
        }
    ];

    vm.selectedBPs = [];
    vm.helpId = '115007891947';
    activate();

    async function activate() {
        await getRiskRulesets();
        vm.businessProcessesLoading = true;
        getBusinessProcesses(null);
        clearObjectSelection();
    }

    function clearObjectSelection() {
        if (vm.selectedBPs.length) {
            vm.businessProcessDataSource.forEach(businessProcess => businessProcess.copy = false);
        }
        vm.selectedBPs = [];
        vm.selectBPsMode = false;
        vm.allBPsSelected = false;
    }

    async function openBusinessProcessModal(businessProcess) {
        const bpModal = $uibModal.open({
            templateUrl: '/App/Components/MaintainBusinessProcess/businessProcessModal/businessProcessModal.html',
            controller: businessProcessModalController,
            controllerAs: 'mbpgvm',
            backdrop: 'static',
            resolve: {
                businessProcess: () => businessProcess,
                riskRuleset: () => vm.selectedRiskRuleset
            }
        });

        try {
            const newBpId = await bpModal.result;
            getBusinessProcesses(newBpId);
        } catch {
            return;
        }
    }

    async function openBusinessProcessGroupModal(group) {
        const bpGroupModal = $uibModal.open({
            templateUrl: '/App/Components/MaintainBusinessProcess/bpGroupModal/bpGroupModal.html',
            controller: bpGroupModalController,
            controllerAs: 'bpgmvm',
            backdrop: 'static',
            resolve: {
                group: () => group,
                groupCount: () => vm.businessProcessGroupsDataSource.length,
                selectedBpId: () => vm.selectedBusinessProcess.Id,
                helpId: () => vm.helpId

            }
        });

        try {
            let newBpGroupId = await bpGroupModal.result;
            changeBusinessProcessSelected(newBpGroupId, vm.selectedBusinessProcess);
        } catch {
            return;
        }
    }

    async function openBpObjectsModal() {
        const bpObjectsModal = $uibModal.open({
            templateUrl: '/App/Components/MaintainBusinessProcess/bpObjectsModal/bpObjectsModal.html',
            controller: bpObjectsModalController,
            controllerAs: 'bpomvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                businessProcess: () => vm.selectedBusinessProcess,
                bpGroup: () => vm.selectedGroup,
                groupObjects: () => vm.selectedObjectsForGroup
            }
        });

        await bpObjectsModal.result;
        changeGroupSelected(vm.selectedGroup);
    }

    async function openRulesetObjectCopyModal() {
        let objectCopyModal = $uibModal.open({
            templateUrl: '/App/Shared/Components/rulesetObjectCopyModal/rulesetObjectCopyModal.html',
            controller: rulesetObjectCopyModalController,
            controllerAs: 'rocmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                objects: () => vm.selectedBPs,
                ruleset: () => vm.selectedRiskRuleset
            }
        });
        try {
            await objectCopyModal.result;
            clearObjectSelection();
        } catch {
            return;
        }
    }

    function changeRiskRuleset() {
        vm.businessProcessGroupsDataSource = null;
        vm.businessProcessDataSource = null;
        vm.selectedObjectsForGroup = null;
        vm.businessProcessesLoading = true;
        getBusinessProcesses(null);
           clearObjectSelection();
    }

    async function getRiskRulesets() {
        vm.riskRulesetsLoading = true;
        let response = await riskRulesetService.getRiskRulesetList();
        response.data = response.data.filter(ruleset => ruleset.IsEnabled);
        vm.riskRulesetDataSource = response.data;
        vm.riskRulesetsLoading = false;
        vm.selectedRiskRuleset = vm.riskRulesetDataSource.find(ruleset => ruleset.IsDefault);
        return vm.riskRulesetDataSource;
    }

    async function getBusinessProcesses(preselect) {
        let response = await businessProcessDataService.getBusinessProcessesByRiskRuleset(vm.selectedRiskRuleset.RulesetId);
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.businessProcessDataSource = response.data;
        if (preselect) {
            response.data.forEach(function (row, i) {
                if (row.Id === preselect) {
                    vm.businessProcessDataSource = response.data.filter(bp => bp.Id !== preselect);
                    vm.selectedBusinessProcess = row;
                    vm.businessProcessDataSource.unshift(vm.selectedBusinessProcess);
                    vm.changeBusinessProcessSelected(row.Id, vm.selectedBusinessProcess);
                }
            });
        }
        vm.businessProcessesLoading = false;
        return vm.businessProcessDataSource
    }


    async function changeGroupSelected(group) {
        vm.selectedGroup = group;
        if (vm.selectedGroup) {
            vm.changeGroupInProgress = true;
            let response = await businessProcessDataService.getBpGroupObjects(vm.selectedBusinessProcess.Id, group.Id);
            vm.selectedObjectsForGroup = response.data.reduce((groupObjects, object) => {
                object.SecurityObject.BusinessProcessObjectId = object.ID;
                object.SecurityObject.SAPAuthFieldGroupings = object.SAPAuthFieldGroupings;
                object.SecurityObject.newFieldValueIsCollapsed = true;
                groupObjects.push(object.SecurityObject);
                return groupObjects;
            }, []);
            vm.changeGroupInProgress = false;
        }
    };

    async function changeBusinessProcessSelected(preselectGroupId, businessProcess) {
        vm.selectedBusinessProcess = businessProcess;
        vm.changeGroupInProgress = true;
        vm.businessProcessGroupsDataSource = null;

        if (vm.selectedBusinessProcess) {
            clearObjectSelection();
            let response = await businessProcessDataService.getBpGroups(vm.selectedBusinessProcess.Id);
            vm.changeGroupInProgress = false;
            vm.businessProcessGroupsDataSource = response.data;
            checkForIncludeAllAccessTypes();

            if (preselectGroupId) {
                vm.selectedGroup = vm.businessProcessGroupsDataSource.find(e => e.Id === preselectGroupId);
            }
            else if (vm.businessProcessGroupsDataSource.length === 1) {
                vm.selectedGroup = vm.businessProcessGroupsDataSource[0];
            }
            else {
                vm.selectedGroup = null;
            }
            changeGroupSelected(vm.selectedGroup);
        } else {
            vm.changeGroupInProgress = false;
        }
    }

    function checkForIncludeAllAccessTypes() {
        const excludedProductsArray = ['NS', 'SF', 'AX7', 'AX', 'D365S', 'INT', 'NAV', 'AX5', 'D365Bc', 'WD', 'ADO', 'AD', 'SL', 'AAD', 'AZ']
        vm.businessProcessGroupsDataSource.forEach(group => {
            const productMatches = excludedProductsArray.some(id => id === group.ProductId);

            if (productMatches) {
                group.enableAllAccessTypes = true;
            } else {
                group.enableAllAccessTypes = false;
            }
        });
    }

    //Delete Selected Object
    async function removeObjectFromBpGroup(bpObject) {
        vm.objectsInProgress = true;
        try {
            await businessProcessDataService.removeBusinessProcessObjectsFromGroup(vm.selectedBusinessProcess.Id, bpObject.BusinessProcessObjectId);
            changeGroupSelected(vm.selectedGroup);
        } catch (response) {
            helperService.showErrorMessage(response.data);
        }
        vm.objectsInProgress = false;
    }

    //Delete Business Process
    async function deleteBusinessProcess(bp) {
        try {
            await swal(helperService.areYouSureParams('Delete Business Process', `Are you sure you want to delete Business Process: ${bp.Name}?`, 'Delete'));
            await businessProcessDataService.deleteBusinessProcess(bp.Id);
            vm.selectedGroup = null;
            vm.selectedBusinessProcess = null;
            vm.businessProcessesLoading = true;
            await getBusinessProcesses(null);

        } catch {
            return;
        }
    }

    //Delete Group
    async function deleteGroup(bpGroup) {
        try {
            await swal(helperService.areYouSureParams('Delete Group', `Are you sure you want to delete Business Process Group: ${bpGroup.Name}?`, 'Delete'));
            await businessProcessDataService.deleteBpGroup(vm.selectedBusinessProcess.Id, bpGroup.Id);
            changeBusinessProcessSelected(null, vm.selectedBusinessProcess);
            vm.selectedGroup = null;
        } catch {
            return;
        }
    }

    function selectBusinessProcess(businessProcess) {
        if (businessProcess.copy) {
            vm.selectedBPs.push(businessProcess);
        }
        else {
            vm.selectedBPs.splice(vm.selectedBPs.indexOf(businessProcess), 1);
        }
        vm.allBPsSelected = vm.businessProcessDataSource.every(bp => bp.copy);
    }
    function selectAllBPs() {
        vm.businessProcessDataSource.forEach(bp => bp.copy = vm.allBPsSelected);
        vm.selectedBPs = vm.businessProcessDataSource.filter(bp => bp.copy);
    }

}