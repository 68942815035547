import createSpinner from "../../../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter } from "../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function (review, $uibModalInstance, $http, helperService, myReviewsDataService, errorsDataService, configurationDataService, manageDataDataService, controlLibraryDataService, onBehalfOf) {

    const vm = this;

    vm.review = review;
    vm.submitReviewCertification = submitReviewCertification;
    vm.helperService = helperService;
    vm.cancel = cancel;
    vm.itemsSelectionChanged = itemsSelectionChanged;
    vm.updateTicket = updateTicket;
    vm.removeTicket = removeTicket;
    vm.tickets = [];
    vm.onBehalfOf = onBehalfOf;
    vm.showGrid = false;

    activate();

    async function activate() {
        if (vm.review.ReviewType === 'SecurityRole' || vm.review.ReviewType === 'Product') {
            if (vm.review.ReviewType === 'SecurityRole') {
                await checkCompanyConfig();
            }
            await getItemsForGrid();
            createRequestItemsGrid();
        } else {
            vm.showGrid = false;
        }
    }

    function updateTicket(ticket) {
        vm.tickets.push(ticket);
    }

    function removeTicket(providerString) {
        vm.tickets = vm.tickets.filter(ticket => ticket.TicketProvider !== providerString);
    }

    // Attachments logic
    async function uploadFiles(files) {
        let fd = new FormData();

        files.forEach(function (file, i) {
            fd.append(files[i].file.name, files[i].file);
        });

        fd.append("DataType", "CertReview");
        fd.append("DataTypeId", vm.review.HeaderId);

        await controlLibraryDataService.addAttachments(fd);
        return files;
    }

    async function checkCompanyConfig() {
        vm.showCompany = vm.review.IncludeCompanyInReviews;
    }

    async function submitReviewCertification(files) {
        vm.pleaseWait = new createSpinner();

        let body = {};
        body.HeaderId = vm.review.HeaderId;
        body.Status = 3;
        body.Notes = vm.notes;
        body.ImRequestItems = vm.selectedItems;
        body.Tickets = vm.tickets;

        try {
            if (onBehalfOf) {
                body.SubmitReviewOnBehalfOf = true;
                await $http.put(`${apiUrl}api/certifications/reviews/${vm.review.HeaderId}/submitonbehalfof`, body);
            } else {
                await $http.put(`${apiUrl}api/certifications/reviews/${vm.review.HeaderId}/complete`, body);
            }
            if (files.length > 0) {
                await uploadFiles(files);
            }
            await helperService.successfulSaveButton(vm.pleaseWait);
            $uibModalInstance.close();
        } catch (err) {
            vm.pleaseWait.loadingValue = false;
            errorsDataService.catch(err);
        }
    }

    function createRequestItemsGrid() {
        let requestItemsGridColumns = [
            { selectable: true, width: 50 },
            { field: "UserName", title: "User", filterable: customAutoCompleteFilter },
            { field: "ProductName", title: "Product", filterable: customAutoCompleteFilter },
        ];
        if (vm.review.ReviewType === 'SecurityRole') {
            requestItemsGridColumns = requestItemsGridColumns.concat([
                { field: "SecurityRoleName", title: "Role", filterable: customAutoCompleteFilter },
                { field: "CompanyName", title: "Company", filterable: customAutoCompleteFilter, hidden: !vm.showCompany }
            ]);
        }

        vm.requestItemsGridOptions = helperService.setNonPageableGridOptions(requestItemsGridColumns, 350);
        vm.requestItemsGridOptions.selectable = false;
        vm.requestItemsGridOptions.filterable = {
            mode: "row"
        };

        vm.requestItemsGridDataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    id: "ItemId"
                }
            },
            transport: {
                read: options => {
                    options.success(vm.gridItems);
                }
            }
        });

    }

    async function getItemsForGrid() {
        vm.loadingGridItems = true;
        let response = await myReviewsDataService.getReviewDetails(vm.review.ReviewType, vm.review.HeaderId, "ReviewType");
        response = response.reduce((acc, curr) => {
            return acc.concat(curr.Items);
        }, []);
        response = response.filter(detail => detail.StatusId === 2);
        const productsArray = [...new Set(response.map(item => item.ProductId))];
        for (let i = 0; i < productsArray.length; i++) {
            let productConfig = await manageDataDataService.getConfigurationByProductId(productsArray[i]);
            const createImRequestSetting = productConfig.data.find(config => config.Name === 'IM_CreateRequestsFromCertReviews');
            if (!createImRequestSetting || createImRequestSetting.Value === '0') {
                response = response.filter(item => item.ProductId !== productsArray[i]);
            }
        }
        if (response.length > 0) {
            vm.showGrid = true;
        }
        vm.gridItems = response;
        vm.loadingGridItems = false;
    }

    function itemsSelectionChanged() {
        vm.selectedItems = vm.requestItemsGrid.selectedKeyNames();
    }

    function cancel() { $uibModalInstance.dismiss(); }

}