import createSpinner from "../../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, helperService, dataService, businessProcessDataService, configurationDataService, group, groupCount, selectedBpId, helpId) {
    const vm = this;

    vm.selectedProductChanged = selectedProductChanged;
    vm.useFioriAppToggleChanged = useFioriAppToggleChanged;
    vm.changeFioriAppSelected = changeFioriAppSelected;
    vm.changeFioriAppServiceSelected = changeFioriAppServiceSelected;
    vm.saveGroup = saveGroup;
    vm.cancel = cancel;

    vm.typeOptions = ['And', 'Or'];

    vm.group = { ...group };
    vm.helpId = helpId;

    activate();

    async function activate() {
        vm.availableProducts = await getProducts();
        vm.excludedAccessProducts = vm.availableProducts.filter(product => product.ID === 'NS' || product.ID === 'SF' || product.ID === 'AX7' || product.ID === 'AX' || product.ID === 'D365S' || product.ID === 'INT' || product.ID === 'NAV' || product.ID === 'AX5' || product.ID === 'D365Bc' || product.ID === 'WD' || product.ID === 'ADO' || product.ID === 'AD' || product.ID === 'SL' || product.ID === 'AAD' || product.ID === 'AZ');

        if (group) {
            vm.bpGroup = {
                ID: group.Id,
                Name: group.Name,
                BusinessProcessId: group.BusinessProcessId,
                IncludeReadViewAccess: group.IncludeReadViewAccess,
                Type: group.Type,
                ProductId: group.ProductId
            }

            vm.enableExcludeInquiryAccess = vm.excludedAccessProducts.some(product => product.ID === group.ProductId);
        } else {
            vm.bpGroup = {
                BusinessProcessId: selectedBpId,
                Type: 'And',
                IncludeReadViewAccess: false
            };

            if (groupCount > 0) {
                const groupNumber = groupCount + 1;
                vm.bpGroup.Name = "Group " + groupNumber;
            } else {
                vm.bpGroup.Name = "Group 1";
            }
            if (vm.availableProducts.length === 1) {
                vm.bpGroup.ProductId = vm.availableProducts[0].ID;
                selectedProductChanged();
            }
        }
    }

    async function getProducts() {
        const response = await dataService.getProducts();
        return response.data;
    }

    async function selectedProductChanged() {
        const selectedProduct = vm.availableProducts.find(product => product.ID === vm.bpGroup.ProductId);
        const s4Enabled = selectedProduct.Type === 'SAP' ? await getS4Config() === 'S4' : false;
        if (selectedProduct.Type === 'SAP' && s4Enabled) {
            vm.s4Enabled = true;
        } else {
            if (vm.bpGroup.AppId) {
                delete bpGroup.AppId;
            }
            vm.s4Enabled = false;
        }

        vm.enableExcludeInquiryAccess = vm.excludedAccessProducts.some(product => product.ID === selectedProduct.ID);
    }

    function useFioriAppToggleChanged() {
        if (vm.useFioriApp) {
            setupFioriAppGrid();
        } else {
            delete vm.bpGroup.AppId;
            delete vm.bpGroup.ServiceIds;
        }
    }

    function setupFioriAppGrid() {
        const columns = [
            { field: "Name", title: "Application Name", filterable: customAutoCompleteFilter },
            { field: "FioriId", title: "Fiori Id", filterable: customAutoCompleteFilter },
        ];

        vm.fioriAppGridOptions = helperService.setMainGridOptions(columns, 300);

        vm.fioriAppGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let response = await businessProcessDataService.getSapFioriApplications(vm.bpGroup.ProductId);
                    options.success(response.data);
                }
            }
        });
    }

    function setupFioriAppServicesGrid(appId) {
        const columns = [
            { selectable: true, width: 50 },
            { field: "ServiceName", title: "Service Name", filterable: customAutoCompleteFilter },
            { field: "Version", title: "Service Version", filterable: customAutoCompleteFilter },
        ];

        vm.fioriAppServicesGridOptions = helperService.setMainGridOptions(columns, 300);
        vm.fioriAppServicesGridOptions.selectable = false;

        vm.fioriAppServicesGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: {
                model: {
                    id: "S4ServiceId"
                }
            },
            transport: {
                read: async options => {
                    let response = await businessProcessDataService.getSapFioriAppServices(vm.bpGroup.ProductId, appId);
                    options.success(response.data);
                }
            }
        });
    }

    function changeFioriAppSelected(kendoEvent) {
        vm.bpGroup.ServiceIds = [];
        const grid = kendoEvent.sender;
        const selectedApp = grid.dataItem(grid.select())
        vm.selectedFioriId = selectedApp.FioriId
        vm.bpGroup.AppId = selectedApp.Id;
        vm.bpGroup.Name = `${selectedApp.FioriId} - ${selectedApp.Name}`;
        setupFioriAppServicesGrid(selectedApp.Id);
    }

    function changeFioriAppServiceSelected() {
        vm.bpGroup.ServiceIds = vm.appServiceGrid.selectedKeyNames();
    }

    async function getS4Config() {
        const response = await configurationDataService.getProductConfigurationValueByName('SAP', 'SAP_ErpType');
        return response.data.Value;
    }

    function saveGroup() {
        vm.bpGroupSaveInProgress = new createSpinner();

        if (vm.bpGroup.ID) {
            updateGroup();
        } else {
            createGroup();
        }
    }

    async function updateGroup() {
        try {
            await businessProcessDataService.updateBusinessProcessGroup(vm.bpGroup);
            await helperService.successfulSaveButton(vm.bpGroupSaveInProgress);
            close();
        } catch {
            vm.bpGroupSaveInProgress.loadingValue = false;
        }

    }

    async function createGroup() {
        try {
            const response = await businessProcessDataService.createBusinessProcessGroup(vm.bpGroup);
            await helperService.successfulSaveButton(vm.bpGroupSaveInProgress);
            close(response.data);
        } catch {
            vm.bpGroupSaveInProgress.loadingValue = false;
        }

    }

    function close(newGroupId) {
        $uibModalInstance.close();
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }

}